// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: 2px;
// $success: #7952b3;
$primary: #9d7d4c;

$theme-colors: (
    "primary": $primary,
);

// Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/accordion";
@import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas"; // Requires transitions
// @import "bootstrap/scss/placeholders";

// Helpers
// @import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

//
// Custom styles
//

@import "./components/fonts";
@import "./components/typography";
@import "global";
@import "./components/buttons";
@import "./components/hero-banner";
@import "./components/header";
@import "./components/footer";
@import "./components/nav";
@import "./components/slide";
@import "./components/article";
@import "./components/testimonial";
@import "./components/privacy";
@import "./components/content";
