.content-text {
    h2 {
        color: $primary;
    }
    a {
        text-decoration: underline;
        font-family: "sigvar-medium";
    }
    p {
        a {
            font-family: "gothammedium";
        }
    }
}

.content-top {
    padding: 120px 0;
    @media (max-width: 768px) {
        padding: 56px 0;
    }
    a {
        cursor: pointer;
    }
}

.text-640 {
    max-width: 640px;
}

.service-excellence {
    background: #fafafa;
    padding: 80px 0 32px;
    @media (max-width: 768px) {
        padding: 56px 0 24px;
    }
    &.our-courses {
        @media (max-width: 768px) {
            padding: 56px 0;
        }
    }
    &.operational {
        background: #fff;
    }
    .text-640 {
        p {
            margin-bottom: 32px;
        }
    }
    &__cards {
        padding: 48px 0;
        filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.05));
        @media (max-width: 768px) {
            padding: 32px 0;
        }
        .row {
            gap: 32px 0;
            @media (max-width: 768px) {
                // filter: drop-shadow(4px 4px 20px #0000000d);
                margin: 0;
            }
            .slick-list {
                padding: 0;
            }
            .slick-dots {
                position: static;
                justify-content: center;
            }
        }
        .card {
            .card-body {
                padding: 48px 48px 36px;
            }
            .card-footer {
                padding: 0 48px 36px;
            }
        }
    }
}

.card {
    border: none;
    // filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.05));
    height: 100%;
    @media (max-width: 768px) {
        filter: none;
    }
    .card-body {
        h4 {
            color: $primary;
            margin-bottom: 16px;
            line-height: 28px;
            a {
                color: $primary;
                &:hover {
                    color: $primary;
                    text-decoration: underline;
                }
            }
        }
    }
    .card-footer {
        background: none;
        border: none;
    }
}

.discover-courses {
    background: #fafafa;
    padding: 120px 0;
    @media (max-width: 768px) {
        padding: 56px 0;
    }
    &__cates {
        max-width: 768px;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        align-items: stretch;
        justify-content: flex-start;
        margin-top: 40px;
        @media (max-width: 768px) {
            gap: 8px;
        }
        &__item {
            width: calc((100% - (24px * 4)) / 5);
            border: 1px solid $primary;
            border-radius: 4px;
            font-size: 14px;
            font-family: "sigvar-medium";
            line-height: 1;
            @media (max-width: 768px) {
                width: calc((100% - (8px * 2)) / 3);
            }
            a {
                padding: 16px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                gap: 16px;
                color: #333;
                height: 100%;
                &:hover {
                    background: rgba(157, 125, 76, 0.1);
                }
            }
        }
    }
}

.our-courses {
    .service-excellence__cards {
        .card {
            .card-body {
                padding: 32px 24px 20px;
                .cates-tag {
                    font-size: 14px;
                    margin-bottom: 12px;
                    font-family: "gothammedium";
                    gap: 5px;
                    .tag {
                        font-size: 12px;
                        color: $primary;
                        border: 1px solid $primary;
                        border-radius: 23px;
                        padding: 2px 10px;
                        font-family: "gothambook";
                    }
                }
                .card-text {
                    margin-bottom: 24px;
                }
                .achieve {
                    font-size: 14px;
                    &__title {
                        color: $primary;
                        font-family: "gothammedium";
                    }
                    &__item {
                        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
                        border-radius: 2px;
                        padding: 12px;
                        margin-top: 8px;
                        img {
                            margin-right: 12px;
                        }
                    }
                }
            }
            .card-footer {
                padding: 20px 24px 20px;
                .btn {
                    padding-right: 0;
                }
            }
        }
    }
    #courses-pagination {
        padding: 0 12px;
        // margin-top: 24px;
    }
}

.sia-pagination {
    ul {
        justify-content: space-between;
        align-items: center;
        max-width: 400px;
        margin: auto;
        li {
            &.first,
            &.last {
                display: none;
            }

            a {
                border: none;
                background: none;
                color: #333;
                font-size: 14px;
                &:focus {
                    box-shadow: none;
                }
                &:hover {
                    background: none;
                    color: $primary;
                }
            }
            &.active {
                a {
                    color: $primary;
                    background: none;
                }
            }
            &.prev,
            &.next {
                padding: 0 10px;
                display: flex;
                align-items: center;
                a {
                    font-size: 0;
                    border: solid #333;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 3px;
                    transform: rotate(-45deg);
                    width: 10px;
                    height: 10px;
                }
                &:not(.disabled) {
                    a {
                        &:hover {
                            border-color: $primary;
                        }
                    }
                }
            }
            &.prev {
                a {
                    transform: rotate(135deg);
                }
            }
        }
    }
}

.dont-find {
    background: linear-gradient(
        to bottom,
        transparent,
        transparent 50%,
        #0e1c42 50%,
        #0e1c42
    );
    color: #fff;
    z-index: 100;
    &.show {
        background: #0e1c42;
    }
    .collapse {
        width: calc(100% - 68px);
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    &__content {
        padding: 20px 0;
        p {
            margin-bottom: 0;
        }
    }
    a.btn {
        border-color: #fff;
        color: #fff;
        background: none;
        @media (max-width: 768px) {
            margin-bottom: 20px;
        }
        &.btn-dont-find {
            font-size: 0;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #0e1c42;
            padding: 0;
            margin-right: 24px;
            &::after {
                content: "";
                border: solid #fff;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                width: 10px;
                height: 10px;
                transform: rotate(-135deg);
                margin-top: 3px;
            }
            &.active {
                &::after {
                    transform: rotate(45deg);
                    margin-top: 0;
                    margin-bottom: 3px;
                }
            }
        }
    }
}

.course-content {
    padding: 80px 0;
    @media (max-width: 768px) {
        padding: 56px 0 0;
    }
    &--customize {
        background: #fafafa;
        a.btn-primary {
            margin-top: 40px;
        }
    }
    h2 {
        color: $primary;
    }
    ul,
    ol {
        padding-left: 20px;
        li {
            color: $primary;
            font-family: "gothammedium";
            line-height: 29px;
            ul {
                li {
                    font-family: "gothambook";
                }
            }
        }
    }
    .course-time {
        font-family: "sigvar-medium";
        font-size: 20px;
        margin: 24px 0;
    }
    &__what-learn {
        margin: 24px 0;
    }
    &__target {
        margin-bottom: 40px;
        &__item {
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
            border-radius: 2px;
            padding: 16px;
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: start;
            font-family: "sigvar-medium";
            img {
                margin-right: 10px;
            }
        }
    }
    .col {
        @media (max-width: 992px) {
            &:last-child {
                padding: 0;
                margin-top: 40px;
            }
            &:first-child {
                margin-top: 0;
            }
        }
    }
    &__target-block {
        background: #fafafa;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 48px 40px;
        text-align: center;
        margin-top: 48px;
        @media (max-width: 992px) {
            padding: 48px 20px;
        }
        @media (max-width: 576px) {
            margin-top: 0;
        }
        .course-content__target__items {
            display: flex;
            gap: 16px;
            width: 100%;
            justify-content: center;
            @media (max-width: 992px) {
                flex-direction: column;
            }
            .course-content__target__item {
                flex: 1;
                justify-content: center;
                @media (max-width: 992px) {
                    justify-content: start;
                }
            }
        }
        .btn {
            margin-top: 32px;
        }
    }
}

.date-available {
    background: #fafafa;
    padding: 120px 0;
    @media (max-width: 768px) {
        padding: 56px 0;
    }
    &__wrap {
        max-width: 590px;
    }
    #datepicker {
        .datepicker-inline {
            width: 100%;
            .table-condensed {
                width: 100%;
            }
        }
    }

    h2 {
        color: $primary;
        margin-bottom: 12px;
    }
    &__calendar {
        &__month {
            margin-bottom: 24px;
            h3 {
                margin: 0;
            }
        }
        &__dates {
            ul {
                gap: 12px;
                @media (max-width: 768px) {
                    gap: 8px;
                }
                li {
                    width: calc((100% - (12px * 6)) / 7);
                    border-radius: 2px;
                    border: 1px solid $primary;
                    color: $primary;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    // padding: 15px 0;
                    cursor: pointer;
                    a {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        padding: 15px 0;
                        width: 100%;
                    }
                    span {
                        &:first-child {
                            font-family: "sigvar-medium";
                            @media (max-width: 768px) {
                                font-size: 14px;
                            }
                        }
                        &:last-child {
                            font-size: 14px;
                            @media (max-width: 768px) {
                                font-size: 12px;
                            }
                        }
                    }
                    &.disabled {
                        opacity: 0.3;
                        cursor: default;
                        &:hover {
                            background: none;
                            color: $primary;
                            a {
                                background: none;
                                color: $primary;
                            }
                        }
                        a {
                            pointer-events: none;
                            cursor: default;
                        }
                    }
                    &:hover,
                    &.active {
                        background-color: $primary;
                        color: #fff;
                        a {
                            background-color: $primary;
                            color: #fff;
                        }
                    }
                    @media (max-width: 768px) {
                        width: calc((100% - (8px * 6)) / 7);
                        a {
                            padding: 8px 0;
                        }
                    }
                }
            }
        }
    }
}

.open-run {
    background: #0e1c42;
    padding: 120px 0;
    color: #fff;
    @media (max-width: 768px) {
        padding: 80px 0;
    }
    &__content {
        max-width: 716px;
        h2 {
            margin-bottom: 16px;
        }
        a.btn {
            margin-top: 32px;
        }
    }
}

.enquiry-content {
    padding: 164px 0 64px;
    @media (max-width: 768px) {
        padding: 120px 0 32px;
    }
    &__item {
        margin-bottom: 64px;
        @media (max-width: 768px) {
            margin-bottom: 40px;
        }
        h2 {
            color: $primary;
            margin-bottom: 24px;
            text-transform: capitalize;
        }
        &--contact {
            p,
            a {
                font-family: "gothammedium";
                color: #4d4d4d;
            }
        }
        &--follow {
            @media (max-width: 768px) {
                margin-bottom: 40px;
            }
            .social {
                ul {
                    justify-content: start !important;
                    gap: 32px;
                    li {
                        a {
                            color: #333;
                        }
                    }
                }
            }
        }
        a {
            &:hover {
                color: $primary !important;
            }
        }
    }
    .enquiry-form {
        .form-control,
        .form-select {
            border-width: 0 0 1px;
            border-radius: 0;
            box-shadow: none;
            padding-left: 5px;
        }
        label {
            color: #84888c;
            opacity: 1;
            text-transform: capitalize;
            padding-left: 0;
        }
        .btn-primary {
            margin-left: 32px;
            @media (max-width: 768px) {
                margin: 32px 0 0;
            }
        }
        .form-check {
            label {
                font-size: 14px;
                padding-left: 16px;
                @media (max-width: 768px) {
                    padding-left: 8px;
                }
            }
            .form-check-input {
                width: 24px;
                height: 24px;
                border-width: 2px;
                border-radius: 0;
                @media (max-width: 768px) {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .form-floating {
            overflow: hidden;
            &.required {
                label {
                    &::after {
                        content: "*";
                        color: #cc0000;
                    }
                }
            }
        }
    }
}

.media-page {
    background: #fafafa;
    padding: 80px 0;
    @media (max-width: 992px) {
        padding: 56px 0;
    }
    h2,
    h4 {
        color: $primary;
    }
    h2 {
        margin-bottom: 40px;
        @media (max-width: 992px) {
            margin-bottom: 24px;
        }
    }
    form {
        max-width: 420px;
        @media (max-width: 992px) {
            max-width: none;
        }
        .input-group {
            border-bottom: 1px solid #84888c;
            input {
                border: none;
                box-shadow: none;
                background: none;
                padding-left: 0;
                &:focus {
                    box-shadow: none;
                    background: none;
                }
            }
            label {
                padding-left: 0;
                color: #84888c;
            }
            button,
            a {
                border: none;
                color: #84888c;
                padding-right: 5px;
                &:hover {
                    background: none;
                    border: none;
                    color: $primary;
                }
            }
        }
    }
    .form-select {
        border: none;
        border-bottom: 1px solid #84888c;
        background-color: transparent;
        box-shadow: none;
        color: #84888c;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        height: 100%;
        background-position: right center;
    }
    &__articles {
        gap: 40px;
        margin-top: 40px;
        @media (max-width: 768px) {
            gap: 24px;
        }
        &__item {
            width: calc((100% - 40px) / 2);
            @media (max-width: 992px) {
                width: 100%;
            }
            @media (max-width: 768px) {
                flex-direction: row-reverse !important;
                border-bottom: 1px solid #e6e6e6;
                padding-bottom: 24px;
            }
            &:last-child {
                border-bottom: none;
            }
            &__img {
                max-width: 132px;
                margin-right: 24px;
                @media (max-width: 768px) {
                    margin-right: 0;
                    margin-left: 24px;
                    max-width: 80px;
                }
                img {
                    width: 100%;
                }
            }
            .cate {
                font-size: 14px;
                color: #84888c;
                font-family: "gothammedium";
                margin-bottom: 8px;
            }
            h4 {
                margin-bottom: 12px;
                @media (max-width: 768px) {
                    margin-bottom: 8px;
                }
            }

            p {
                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }

        &__pagination {
            margin-top: 56px;
            @media (max-width: 768px) {
                margin-top: 40px;
            }
        }
    }
}

.date-read {
    color: #84888c;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 8px;
    }
    span {
        &:first-child {
            display: flex;
            align-items: center;
            gap: 8px;
            &::after {
                content: "";
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #84888c;
                display: inline-block;
            }
        }
    }
}

.tags {
    gap: 12px;
    .tag {
        a {
            border: 1px solid $primary;
            color: $primary;
            font-size: 14px;
            padding: 4px 10px;
            border-radius: 23px;
            display: inline-block;
            @media (max-width: 768px) {
                font-size: 12px;
            }
            &:hover {
                background-color: $primary;
                color: #fff;
            }
        }
    }
}

.our-clients {
    padding: 160px 0;
    @media (max-width: 992px) {
        padding: 80px 0;
    }
    .content-text {
        max-width: 708px;
        margin-bottom: 40px;
        @media (max-width: 992px) {
            margin-bottom: 32px;
        }
    }
    &__logos {
        max-width: 836px;
        gap: 40px;
        @media (max-width: 992px) {
            gap: 20px;
        }
        @media (max-width: 768px) {
            gap: 10px;
        }
    }
    &__logo {
        width: calc((100% - (40px * 3)) / 4);
        @media (max-width: 992px) {
            width: calc((100% - (20px * 3)) / 4);
        }
        @media (max-width: 768px) {
            width: calc((100% - (10px * 3)) / 4);
        }
    }
}

.our-awards {
    padding: 120px 0;
    background: #fafafa;
    @media (max-width: 768px) {
        padding: 56px 0;
    }
    .content-text {
        max-width: 720px;
        h2 {
            margin-bottom: 16px;
        }
        p.top-desc {
            max-width: 520px;
            margin: 0 auto 24px;
        }
        p.last-desc {
            margin: 0;
        }

        .special {
            margin-bottom: 24px;
            p {
                margin-bottom: 0;
                &:first-child {
                    margin-bottom: 5px;
                }
                &:last-child {
                    color: $primary;
                    font-family: "gothammedium";
                    font-size: 18px;
                }
            }
            strong {
                font-size: 18px;
                font-family: "gothammedium";
                margin-bottom: 10px;
                display: block;
                margin-bottom: 12px;
            }
            img {
                margin-top: 12px;
                @media (max-width: 767px) {
                    margin-top: 0;
                }
            }
            .awards {
                @media (max-width: 767px) {
                    gap: 24px;
                    margin-top: 24px;
                }
            }
        }
    }
}

.contact-us-page {
    // #sticky-header:not(.sticky) {
    //     background: linear-gradient(
    //         180deg,
    //         rgba(0, 0, 0, 0.18) 32.29%,
    //         rgba(0, 0, 0, 0) 100%
    //     );
    // }
    .enquiry-content {
        padding: 80px 0;
    }
    .find-us {
        h2 {
            margin-bottom: 32px;
        }
        &__item {
            margin-bottom: 32px;
            h3 {
                margin-bottom: 16px;
            }
            p {
                margin: 0;
            }
        }
        .where {
            background: #0e1c42;
            padding-top: 120px;
            padding-bottom: 120px;
            color: #fff;
            @media (max-width: 1200px) {
                padding-top: 80px;
                padding-bottom: 80px;
            }
            @media (max-width: 768px) {
                padding-top: 56px;
                padding-bottom: 56px;
            }
        }
        iframe {
            width: 100% !important;
            height: 100% !important;
            @media (max-width: 768px) {
                height: 460px !important;
            }
        }
    }
}

.article-content {
    padding: 80px 0;
    @media (max-width: 768px) {
        padding: 56px 0;
    }
    .sidebar {
        @media (max-width: 768px) {
            margin-top: 40px;
            padding-top: 40px;
            padding-bottom: 40px;
            background-color: #fafafa;
        }
        &__item {
            margin-bottom: 80px;
            @media (max-width: 992px) {
                margin-bottom: 40px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            h2 {
                color: $primary;
                margin-bottom: 24px;
            }
            .social {
                ul {
                    gap: 32px;
                    li {
                        a {
                            color: #333;
                            &:hover {
                                color: $primary;
                            }
                        }
                    }
                }
            }
            .related-articles {
                &__item {
                    border-bottom: 1px solid #e6e6e6;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    &:last-child {
                        padding-bottom: 0;
                        margin-bottom: 0;
                        border: none;
                    }
                    h4 {
                        a {
                            color: #333;
                            &:hover {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
}
