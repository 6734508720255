@font-face {
    font-family: "gothambook";
    src: url("../fonts/gotham-book-webfont.woff2") format("woff2"),
        url("../fonts/gotham-book-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "gothammedium";
    src: url("../fonts/gotham-medium-webfont.woff2") format("woff2"),
        url("../fonts/gotham-medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "gothambold";
    src: url("../fonts/gotham-bold-webfont.woff2") format("woff2"),
        url("../fonts/gotham-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sigvar-bold";
    src: url("../fonts/sigvar_serial-bold-webfont.woff2") format("woff2"),
        url("../fonts/sigvar_serial-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sigvar-light";
    src: url("../fonts/sigvar-serial-light-regular-webfont.woff2")
            format("woff2"),
        url("../fonts/sigvar-serial-light-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sigvar-medium";
    src: url("../fonts/sigvar-serial-medium-regular-webfont.woff2")
            format("woff2"),
        url("../fonts/sigvar-serial-medium-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
