.footer {
    padding: 100px 0;
    background: url(../images/footer/bg-footer.png) no-repeat bottom left #fff;
    @media (max-width: 768) {
        padding: 48px 0;
        background-size: 350px auto;
    }
    &__wrap {
        max-width: 816px;
    }
    &__nav {
        ul {
            margin: 48px 0 32px;
            gap: 24px;
            li {
                a {
                    color: #333333;
                    line-height: 19px;
                    &:hover {
                        color: $primary;
                        &::after {
                            border-color: $primary;
                        }
                    }
                    &.active {
                        color: $primary;
                    }
                    @media (max-width: 768) {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        &:after {
                            content: "";
                            display: block;
                            border: solid #1a1a1a;
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 3px;
                            transform: rotate(-45deg);
                        }
                    }
                }
                &::after {
                    content: "|";
                    color: #e6e6e6;
                    font-size: 14px;
                    display: inline-block;
                    margin-left: 24px;
                    @media (max-width: 768) {
                        content: none;
                    }
                }
                &:last-child {
                    &::after {
                        content: none;
                    }
                    border-bottom: none !important;
                }
                @media (max-width: 768) {
                    width: 100%;
                    border-bottom: 1px solid #e6e6e6;
                    padding-bottom: 24px;
                }
            }
        }
    }
    .desc {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 48px;
        color: #666666;
    }
    .social {
        ul {
            gap: 32px;
            margin-bottom: 48px;
            li {
                a {
                    color: #4d4d4d;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }
    .airline {
        ul {
            gap: 20px 32px;
            margin-bottom: 48px;
        }
    }
    .copyright {
        font-size: 16px;
        line-height: 19px;
    }
}
