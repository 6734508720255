.article-slide {
    padding-top: 80px;
    @media (max-width: 991px) {
        padding-top: 0;
    }
    &__items {
        width: 40%;
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    &__item {
        &.slick-dotted.slick-slider {
            margin-bottom: 40px;
        }
        &__content {
            // padding-right: 56px;
            &--right,
            &--left {
                padding-right: 0;
                width: 45%;
                @media (max-width: 991px) {
                    width: 100%;
                    padding: 56px 0 0;
                }
            }
            @media (max-width: 991px) {
                padding: 56px 6px 0;
            }
            h2 {
                color: $primary;
                margin-bottom: 16px;
            }
            p {
                margin-bottom: 32px;
            }
        }
        &__img {
            width: 65%;
            @media (max-width: 1600px) {
                width: 55%;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            img {
                width: 100%;
            }
        }
    }
    &__img {
        width: 70%;
        @media (max-width: 1600px) {
            width: 60%;
        }
        @media (max-width: 991px) {
            margin-right: 0;
            width: 100%;
        }
        img {
            width: 100%;
        }
    }
    .slide-res-nav {
        width: 100%;
        @media (max-width: 991px) {
            justify-content: center !important;
            position: static !important;
            margin-bottom: 56px;
        }
        .slide-res-arrows {
            gap: 16px;
            @media (max-width: 991px) {
                display: none !important;
            }
        }
    }
}

.article {
    padding: 80px 0;
    @media (max-width: 991px) {
        padding: 0 0 56px;
    }
}

.events-slide {
    padding: 80px 0;
    @media (max-width: 992px) {
        padding: 0 0 88px;
    }
    &__imgs {
        width: 45%;
        @media (max-width: 991px) {
            width: 100%;
        }
        img {
            width: 100%;
            border-radius: 8px;
        }
    }
    &__img {
        @media (max-width: 991px) {
            position: relative;
            min-height: 460px;
            &::before {
                position: absolute;
                content: "";
                z-index: 0;
                width: 100%;
                height: 100%;
                display: block;
                background: linear-gradient(
                    360deg,
                    rgba(0, 0, 0, 0.7) 42.39%,
                    rgba(0, 0, 0, 0) 92.83%
                );
                box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
            }
        }
    }
    &__wrap-items {
        padding-left: 48px;
        width: 55%;
        @media (max-width: 991px) {
            width: 100%;
            padding: 0 40px;
            display: flex;
            flex-direction: column;
            position: absolute;
            bottom: -32px;
            text-align: center;
        }
        @media (max-width: 576px) {
            padding: 0 20px;
        }
        .slide-res-nav {
            margin-top: 24px;
            @media (max-width: 991px) {
                margin: 32px 0 0;
                justify-content: center !important;
                .slide-res-arrows {
                    display: none !important;
                }
            }
        }
    }
    &__items {
        margin-bottom: 0 !important;
    }
    &__item {
        h2,
        h3,
        a {
            color: $primary;
            @media (max-width: 991px) {
                color: #fff;
            }
        }
        h2 {
            margin-bottom: 16px;
        }
        h3 {
            margin-bottom: 12px;
        }
        p {
            margin-bottom: 32px;
            @media (max-width: 991px) {
                color: #fff;
            }
        }
    }
}

.about-slide {
    background: #fafafa;
    @media (max-width: 991px) {
        padding: 0;
        .events-slide__item h2,
        .events-slide__item a {
            color: $primary;
        }
        .events-slide__item p {
            color: #333;
        }
        .events-slide__img {
            min-height: auto;
            &::before {
                content: none;
            }
        }
    }
    &__wrap-items {
        padding: 32px 0;
        width: calc((100% - 56px) / 2);
        @media screen and (min-width: 992px) and (max-width: 1200px) {
            padding-top: 0;
        }
        @media (max-width: 991px) {
            position: static;
            text-align: left;
            width: 100%;
        }
        .slide-res-nav {
            margin: 0;
        }
    }
    .events-slide__imgs {
        width: calc((100% - 56px) / 2);
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    .events-slide__imgs img {
        border-radius: 0;
    }
    .events-slide__item p {
        margin-bottom: 24px;
    }
}
