.testimonial {
    background: #f7f7f7;
    padding: 135px 0;
    @media (max-width: 992px) {
        padding: 80px 0;
    }
    @media (max-width: 768px) {
        padding: 0;
        background-color: #fff;
    }
    &__slide {
        background-color: #fff;
        padding: 48px;
        max-width: 988px;
        @media (max-width: 992px) {
            padding: 24px;
        }
        @media (max-width: 768px) {
            padding: 48px 0;
        }
    }
    &__imgs {
        width: 204px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    &__img {
        width: 204px;
        height: 230px;
        @media (max-width: 768px) {
            width: 100%;
            height: auto;
        }
        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
        }
    }
    &__wrap {
        padding-left: 48px;
        width: calc(100% - 204px);
        @media (max-width: 992px) {
            padding-left: 24px;
        }
        @media (max-width: 768px) {
            padding: 32px 0 0;
            width: 100%;
        }
        .slide-res-nav {
            @media (max-width: 768px) {
                justify-content: center !important;
                .slide-res-arrows {
                    display: none !important;
                }
            }
        }
    }
    &__item {
        &__content {
            h3 {
                color: $primary;
            }
        }
    }
}
