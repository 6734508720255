.hero-banner {
    color: #fff;
    &__item {
        // height: 920px;
        height: 100vh;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 1200px) {
            height: 674px;
        }
        @media (max-width: 768px) {
            height: 422px;
        }
        &--small {
            height: 674px;
            @media (max-width: 768px) {
                height: 422px;
            }
        }
        &__content {
            width: 630px;
            @media (max-width: 768px) {
                width: 100%;
                padding: 0 20px;
                height: 100%;
                position: relative !important;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .btn {
                @media (max-width: 768px) {
                    position: absolute;
                    bottom: 30px;
                    width: calc(100% - 40px);
                }
            }
            .tag {
                border: 1px solid #ffffff;
                border-radius: 23px;
                padding: 4px 14px;
                font-size: 20px;
                width: fit-content;
                margin-bottom: 16px;
                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }
        }
    }

    .slide-res-nav {
        width: 630px;
        @media (max-width: 768px) {
            width: 100%;
            padding: 0 20px;
        }
        .slick-dots {
            li {
                button {
                    &::before {
                        color: rgba(255, 255, 255, 0.5);
                    }
                }
                &.slick-active button:before {
                    color: #fff;
                }
            }
        }
        .slick-arrow {
            border: none;
            &::before {
                border-color: #fff;
            }
            @media (max-width: 768px) {
                display: none !important;
            }
        }
    }

    h1,
    h2 {
        text-transform: none !important;
    }
    h1 {
        font-size: 48px;
        margin-bottom: 20px;
        @media (max-width: 768px) {
            font-size: 32px;
            line-height: 38px;
        }
    }
    p {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 32px;
        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__img {
        // height: 674px;
        height: 100vh;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 1200px) {
            height: 674px;
        }
        @media (max-width: 768px) {
            height: 422px;
        }
        &__content {
            &.animate__slideInUp {
                background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.18) 0%,
                    rgba(0, 0, 0, 0) 100%
                );
                padding-top: 40px;
            }
        }
    }

    &__wrap-title {
        margin-bottom: 40px;
        gap: 16px;
        @media (max-width: 768px) {
            margin-bottom: 20px;
        }
        h2 {
            font-size: 40px;
            margin-bottom: 0;
            line-height: 1.1;
            @media (max-width: 768px) {
                font-size: 32px;
                line-height: 38px;
            }
        }
        p {
            display: none;
            margin: 12px 0 0;
            &.active {
                display: block;
            }
        }
        .tag {
            display: none;
            background: rgba(157, 125, 76, 0.7);
            border-radius: 64px;
            font-size: 22px;
            font-family: "sigvar-medium";
            padding: 16px 24px;
            &.active {
                display: inline-flex;
            }
            @media (max-width: 768px) {
                font-size: 14px;
                padding: 8px 12px;
            }
        }
    }
    &__title {
        max-width: 70%;
        @media (max-width: 992px) {
            max-width: none;
        }
        .btn {
            color: #fff;
            margin: 0 24px 12px 0;
            display: none;
            &.active {
                display: inline-block;
            }
        }
    }
}
