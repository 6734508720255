.privacy-content {
    background-color: #fafafa;
    padding: 80px 0;
    @media (max-width: 992px) {
        padding: 56px 0;
    }
    &__wrap {
        max-width: 960px;
    }
    ol,
    ul {
        padding-left: 20px;
        ul,
        ol {
            li {
                list-style: lower-roman;
                ul,
                ol {
                    li {
                        list-style: disc;
                        ul,
                        ol {
                            li {
                                list-style: square;
                            }
                        }
                    }
                }
            }
        }
    }
    h4 {
        color: $primary;
        padding-top: 32px;
    }
}
