.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: fit-content;
    font-size: 18px;
    // line-height: 19px;
    height: auto;
    padding: 12px 20px;
    font-family: "sigvar-medium";
    box-shadow: none;
    text-transform: capitalize;
    border: 1px solid $primary;
    color: $primary;
    border-radius: 2px;
    &:hover {
        background: rgba(157, 125, 76, 0.1);
        color: $primary;
        border: 1px solid $primary;
    }
    &.btn-primary {
        color: #fff;
        background: $primary;
        border: none;
        &:hover {
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #9d7d4c;
        }
        &.btn-icon-right {
            &::after {
                content: url(../images/arrow-btn-white.svg);
            }
        }
    }
    &.btn-outline {
        border: none;
        background: none;
        &:hover {
            text-decoration: underline;
        }
    }
    &.btn-icon-right {
        &::after {
            content: url(../images/arrow-btn.svg);
            display: inline-block;
            margin-left: 15px;
            height: 20px;
        }
    }
    &.btn-full-width-mobile,
    &.btn-fwm {
        @media (max-width: 768px) {
            width: 100%;
            &::after {
                content: none !important;
            }
        }
    }
    &.btn-white {
        border-color: #fff;
        color: #fff;
        background: none;
        &.btn-icon-right {
            &::after {
                content: url(../images/arrow-btn-white.svg);
            }
        }
    }
    &.dropdown-toggle {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        .visually-hidden {
            font-size: 0;
            display: none;
        }
    }
    &.btn-back {
        border: none;
        padding: 0;
        color: #333;
        margin-bottom: 24px;
        &:hover {
            background: none;
            color: $primary;
        }
    }
}

.btn-sub-navs {
    padding: 0 7px;
    cursor: pointer;
    span {
        border: solid #333;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(-45deg);
    }
}

.btn-back-main-navs {
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: #0e1c42;
    padding: 20px;
    font-family: "sigvar-light";
    gap: 10px;
    cursor: pointer;
    border-top: 1px solid #333;
    span {
        color: #fff;
        &.arrow-left {
            border: solid #fff;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 5px;
            transform: rotate(135deg);
        }
    }
}
