.slide-res-nav {
    .slide-res-dots {
        .slick-dots {
            position: static;
        }
    }
    .slide-res-arrows {
        gap: 16px;
        .slick-arrow {
            transform: none;
        }
    }
}

.slide-home-content {
    margin-bottom: 0 !important;
    &:hover {
        .slick-arrow {
            opacity: 1;
            visibility: visible;
        }
    }
    &__item {
        height: 720px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 768px) {
            height: 472px;
        }
        h1 {
            color: #fff;
            margin-bottom: 80px;
            @media (max-width: 768px) {
                font-size: 24px;
                margin-bottom: 48px;
                padding: 0 56px;
                line-height: 29px;
            }
        }
    }
    .slick-dots {
        bottom: 25px;
        justify-content: center;
        @media (max-width: 768px) {
            display: none !important;
        }
    }
    .slick-arrow {
        height: 100%;
        border: none;
        background: rgba(0, 0, 0, 0.2);
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s ease;
        &::before {
            border: 2px solid #fff;
            border-width: 0 4px 4px 0;
            padding: 8px;
            @media (max-width: 768px) {
                border-width: 0 2px 2px 0;
                padding: 5px;
            }
        }
        @media (max-width: 768px) {
            width: 56px !important;
            opacity: 1;
            visibility: visible;
        }
    }
}
