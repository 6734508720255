.header {
    z-index: 1;
    margin-bottom: -104px;
    @media (max-width: 992px) {
        margin-bottom: -91px;
    }
    &#sticky-header:not(.sticky) {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.18) 32.29%,
            rgba(0, 0, 0, 0) 100%
        );
    }

    &.sticky {
        position: fixed !important;
        top: 0;
        width: 100%;
        z-index: 100;
    }
    &.sticky,
    &.has-dropdown {
        background: #fff !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        transition: all 0.5s linear;
        img {
            display: none;
            &.logo-sticky {
                display: block;
            }
        }
        .header__content {
            height: auto;
            padding: 24px 0;
            .navbar {
                .nav-link {
                    color: #333;
                    &:hover,
                    &.active {
                        color: $primary;
                    }
                }
            }
        }
        .navbar {
            .nav-item {
                &.dropdown {
                    .btn.dropdown-toggle {
                        &::after {
                            border-color: #333;
                        }
                        &:hover {
                            &::after {
                                border-color: $primary;
                            }
                        }
                    }
                }
            }
        }
        .navbar-toggler {
            color: #0e1c42;
        }
    }
    .logo {
        max-width: 169px;
        @media (max-width: 992px) {
            max-width: 120px;
        }
        &.logo-sticky {
            display: none;
        }
    }

    &__content {
        height: 104px;
        @media (max-width: 992px) {
            height: auto;
            padding: 24px 0;
        }
    }
    .navbar {
        background: none;
        padding: 0;
        position: unset;
        .navbar-nav {
            gap: 40px;
        }
        .nav-item {
            &.dropdown {
                display: flex;
                align-items: center;
                position: unset;
                .btn.dropdown-toggle {
                    width: 26px;
                    &::after {
                        color: #fff;
                        border: solid #fff;
                        border-width: 0 2px 2px 0;
                        padding: 4px;
                        transform: rotate(45deg);
                        margin: 0 0 5px;
                        transition: all 0.5s ease-in-out;
                    }
                    &:hover {
                        &::after {
                            border-color: #fff;
                        }
                    }
                    &.show {
                        &::after {
                            transform: rotate(-135deg);
                            margin: 2px 0 0;
                        }
                    }
                }
            }
        }
        .nav-link {
            color: rgba(255, 255, 255, 0.95);
            padding: 0;
            font-size: 20px;
            line-height: 24px;
            text-transform: none !important;
            font-family: "sigvar-light";
            text-transform: none !important;
            // &:hover,
            &.active {
                color: #fff;
                font-family: "sigvar-medium";
            }
        }
        .dropdown-menu {
            border: none;
            background: #ffffff;
            box-shadow: 0px 4px 10px 6px rgba(0, 0, 0, 0.08);
            border-radius: 0;
            padding: 0;
            align-items: stretch;
            // gap: 48px;
            margin-top: 0;
            // min-width: 700px;
            min-width: 100%;
            background: linear-gradient(100deg, #fff 70%, #fafafa 30%);
            &.show {
                display: flex;
                right: 0;
                // transform: translateX(-50%);
                // left: 50%;
                // min-width: 670px;
            }
            li {
                padding: 16px 0;
                border-bottom: 1px solid #f2f2f2;
                &:first-child,
                &:last-child {
                    border-bottom: none;
                }
                &:first-child {
                    color: $primary;
                    text-transform: none !important;
                    font-family: "sigvar-medium";
                    font-size: 18px;
                    line-height: 24px;
                    a {
                        color: $primary;
                        font-size: 18px;
                    }
                }
                .dropdown-item {
                    padding: 0;
                    text-transform: none !important;
                    color: #666666;
                    font-size: 15px;
                    white-space: normal;
                    &:hover {
                        background: none;
                        color: $primary;
                    }
                }
            }
            .dropdown-tabs {
                margin: 0;
                .col-parent {
                    margin-top: 0;
                    padding: 0;
                    &.has-bg {
                        background-color: #fafafa;
                        border-left: 1px solid #f2f2f2;
                    }
                }
                .nav-tabs {
                    border: none;
                    padding: 48px 0;
                    .nav-item {
                        border: none;
                        &:first-child {
                            padding-top: 0;
                        }
                        .nav-link {
                            font-size: 18px;
                            color: #666;
                            border: none;
                            &:hover {
                                text-decoration: underline;
                            }
                            &.active {
                                font-weight: normal;
                                color: $primary;
                                &:hover {
                                    text-decoration: none;
                                }
                                svg {
                                    display: inline-block;
                                    margin-left: 20px;
                                }
                            }
                            svg {
                                display: none;
                            }
                            @media (max-width: 1199px) {
                                font-size: 15px;
                                svg {
                                    width: 16px;
                                }
                            }
                        }
                    }
                }
                .tab-content {
                    .tab-pane {
                        padding: 48px;
                        .main-link-tab {
                            font-size: 18px;
                            font-family: sigvar-medium;
                            line-height: 24px;
                            display: block;
                            padding-bottom: 16px;
                        }
                        ul {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 0 16px;
                            li {
                                width: calc(50% - 8px);
                                &:first-child {
                                    font-family: gothambook;
                                    font-size: 15px;
                                    line-height: 21px;
                                    color: #666;
                                    a {
                                        font-family: gothambook;
                                        font-size: 15px;
                                        line-height: 21px;
                                        color: #666;
                                        &:hover {
                                            color: $primary;
                                        }
                                    }
                                }
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .navbar-toggler {
        color: #fff;
    }

    .navbar-mobile {
        height: 100vh;
        display: none;
        &.show {
            display: flex;
        }
        .offcanvas-header {
            background: #0e1c42;
            padding: 24px 20px;
            .logo {
                max-width: 120px;
                display: block;
            }
            .btn-close {
                opacity: 1;
                box-shadow: none;
            }
        }
        .offcanvas-body {
            padding: 0;
            overflow-x: hidden;
            ul {
                padding: 0 20px;
                border-bottom: 1px solid #f2f2f2;
                li {
                    padding: 24px 0;
                    font-family: "sigvar-light";
                    font-size: 20px;
                    a {
                        color: #333;
                        &:hover,
                        &.active {
                            color: $primary;
                        }
                    }
                    &.has-btn {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
            #sub-nav-mobile {
                transition: 0.3s ease-in-out;
                position: absolute;
                width: 100%;
                top: 0;
                left: 100%;
                z-index: 1;
                height: calc(100vh + 100px);
                background-color: #fff;
                ul {
                    li {
                        font-size: 16px;
                        padding: 16px 0;
                        font-family: "gothambook";
                        border: none;
                        // &:first-child {
                        //     font-size: 18px;
                        //     color: $primary;
                        //     border-color: $primary;
                        //     font-family: "sigvar-light";
                        //     a {
                        //         color: $primary;
                        //     }
                        // }
                        a {
                            color: #4d4d4d;
                            &:hover,
                            &.active {
                                color: $primary;
                            }
                        }
                    }
                }
                .btn-collapse-nav {
                    width: 100%;
                    background-color: #fff;
                    justify-content: space-between;
                    border-color: #f2f2f2;
                    color: #333;
                    border-width: 0 0 1px 0;
                    &[aria-expanded="true"] {
                        color: $primary;
                        background-color: #fafafa;
                        .caret {
                            transform: rotate(225deg);
                        }
                    }
                    .caret {
                        color: #333;
                        border: 2px solid #333;
                        border-width: 0 2px 2px 0;
                        margin: 0 0 5px;
                        padding: 3px;
                        transform: rotate(45deg);
                        content: "";
                        transition: all 0.3s ease-in-out;
                    }
                }
                .accordion-header {
                    margin: 0;
                }
            }
        }
    }
}
