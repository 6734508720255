body {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    font-family: "gothambook";
    letter-spacing: -0.011em;
    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 22px;
    }
}

a {
    text-decoration: none;
}

strong {
    font-family: "gothammedium";
    font-weight: 400;
}

.animate {
    &__delay {
        &__custom-1 {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
        }
        &__custom-2 {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }
        &__custom-3 {
            -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
        }
        &__custom-4 {
            -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
        }
        &__custom-5 {
            -webkit-animation-delay: 0.7s;
            animation-delay: 0.7s;
        }
        &__custom-6 {
            -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
        }
        &__custom-7 {
            -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
        }
        &__custom-8 {
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
        }
    }
}

@media (max-width: 767px) {
    *[class*="animate__delay__custom"] {
        -webkit-animation-delay: 0s !important;
        animation-delay: 0s !important;
    }
}
