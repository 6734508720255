h1,
h2,
h3,
h4,
h5,
h6 {
    font-style: normal;
    font-weight: normal;
    font-family: "sigvar-medium";
    line-height: 1;
}
h1 {
    font-size: 40px;
    @media (max-width: 992px) {
        font-size: 32px;
    }
}

h2 {
    font-size: 32px;
    line-height: 38px;
    @media (max-width: 992px) {
        font-size: 24px;
        line-height: 29px;
    }
}

h3 {
    font-size: 26px;
    @media (max-width: 992px) {
        font-size: 22px;
    }
}

h4 {
    font-size: 22px;
    @media (max-width: 992px) {
        font-size: 20px;
    }
}

h5 {
    font-size: 20px;
    @media (max-width: 992px) {
        font-size: 18px;
    }
}

h6 {
    font-size: 18px;
    @media (max-width: 992px) {
        font-size: 16px;
    }
}
